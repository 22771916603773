import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SiteLayout } from "../../../components/SiteLayout";
import { Seo } from "../../../components/Seo";

interface Props {
  data: any;
}

const EventPage: React.FC<Props> = ({ data }) => {
  const titleImageData =
    data.currentEvent.titleImage?.asset?.gatsbyImageData ||
    data.currentEvent.eventCategory.defaultTitleImage?.asset?.gatsbyImageData;

  const description =
    data.currentEvent.description ||
    data.currentEvent.eventCategory.defaultDescription ||
    "";

  const dateString = data.currentEvent.eventEndDate
    ? `${data.currentEvent.eventDate} - ${data.currentEvent.eventEndDate}`
    : data.currentEvent.eventDate;

  return (
    <SiteLayout>
      <section className="bg-transparent text-white h-64 xl:h-96">
        {titleImageData && (
          <GatsbyImage
            className="h-full"
            image={titleImageData}
            alt={`Titelbild von ${data.currentEvent.title}`}
          />
        )}
      </section>
      <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <span>{data.currentEvent.eventCategory.title}</span>
          <h1>{data.currentEvent.title}</h1>
          {description
            .split("\n\n")
            .filter((p) => !!p)
            .map((descriptionParagraph, i) => (
              <p key={i}>
                {descriptionParagraph
                  .split("\n")
                  .filter((p) => !!p)
                  .map((part, i) => (
                    <React.Fragment key={i}>
                      {part}
                      {i < descriptionParagraph.split("\n").length - 1 && (
                        <br />
                      )}
                    </React.Fragment>
                  ))}
              </p>
            ))}
          <p>
            <b>Datum:</b> {dateString}
            <br />
            {data.currentEvent.eventStartTime && (
              <>
                <b>Uhrzeit:</b> {data.currentEvent.eventStartTime}
                <br />
              </>
            )}
            <b>Treffpunkt:</b>{" "}
            {data.currentEvent.location || "Zurzeit noch unbekannt"}
          </p>
        </div>
      </section>
    </SiteLayout>
  );
};

export const Head = ({ location: { pathname }, data }) => {
  const titleImagePublicUrl =
    data.currentEvent.titleImage?.asset?.publicUrl ||
    data.currentEvent.eventCategory.defaultTitleImage?.asset?.publicUrl;

  return (
    <Seo
      title={`${data.currentEvent.title} am ${data.currentEvent.eventDate}`}
      relativePageUrl={pathname}
      absoluteImageUrl={titleImagePublicUrl}
    />
  );
};

export default EventPage;

export const query = graphql`
  query ($id: String) {
    currentEvent: sanityEvents(id: { eq: $id }) {
      title
      description
      eventCategory {
        title
        defaultDescription
        defaultTitleImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            publicUrl
          }
        }
      }
      titleImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          publicUrl
        }
      }
      eventDate(formatString: "DD.MM.yyyy")
      eventEndDate(formatString: "DD.MM.yyyy")
      eventStartTime
      location
    }
  }
`;
