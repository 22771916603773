import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import LogoFarbig from "../images/logo-farbig.png";

interface MetaObject {
  name?: string;
  property?: string;
  content: string;
}

interface Props {
  title: string;
  relativePageUrl: string;
  description?: string;
  meta?: MetaObject[];
  absoluteImageUrl?: string;
  relativeImageUrl?: string;
}

export const Seo: React.FC<Props> = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = props.description || site.siteMetadata.description;

  const url = `${site.siteMetadata.siteUrl}${props.relativePageUrl}`;
  const imageUrl =
    props.absoluteImageUrl ||
    `${site.siteMetadata.siteUrl}${props.relativeImageUrl || LogoFarbig}`;

  const metaTags: MetaObject[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: props.title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:locale`,
      content: `de_DE`,
    },
    {
      property: `og:site_name`,
      content: `exSPIRIence`,
    },
    {
      property: `og:image`,
      content: imageUrl,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: props.title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: imageUrl,
    },
  ];

  if (props.meta) {
    props.meta.forEach((newMetaTag) => {
      const metaTagWithSameName =
        !!newMetaTag.name &&
        metaTags.find((metaTag) => metaTag.name === newMetaTag.name);
      if (!!metaTagWithSameName) {
        metaTagWithSameName.content = newMetaTag.content;
        return;
      }

      const metaTagWithSameProperty =
        !!newMetaTag.property &&
        metaTags.find((metaTag) => metaTag.property === newMetaTag.property);
      if (!!metaTagWithSameProperty) {
        metaTagWithSameProperty.content = newMetaTag.content;
        return;
      }

      metaTags.push(newMetaTag);
    });
  }

  const titleLengthLimit = 60;
  const spacer = " | ";
  const fullTitle = `${props.title}${spacer}${site.siteMetadata.title}`;

  return (
    <>
      <title>
        {fullTitle.length > titleLengthLimit ? props.title : fullTitle}
      </title>
      {metaTags.map((tag) => (
        <meta key={`${tag.name}${tag.property}`} {...tag} />
      ))}
    </>
  );
};
